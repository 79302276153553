@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root{
  --hue: 0;
  --saturation: 0%;
  --title-color: hsl(var(--hue), var(--saturation), 20%);
  --title-color-dark: hsl(var(--hue), var(--saturation), 0%);
  --text-color: hsl(var(--hue), var(--saturation), 30%);
  --body-color: hsl(var(--hue), var(--saturation), 97.5%);
  --container-color: #fff;

  --body-font: 'Poppins', sans-serif;

  --big-font-size: 3.5rem;
  --h1-font-size: 2.5rem;
  --h2-font-size: 2rem;
  --h3-font-size: 1.5rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.75rem;
  --smaller-font-size: 0.625rem;
  --tiny-font-size: 0.5rem;

  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

@media screen and (max-width: 992px) {
  :root{
    --big-font-size: 3rem;
    --h1-font-size: 2rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 0.875rem;
    --small-font-size: 0.75rem;
    --smaller-font-size: 0.625rem;
    --tiny-font-size: 0.5rem;
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body,
button,
input,
textarea{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,h2,h3 {
  color: var(--title-color);
  font-weight: var(--font-bold);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

main {
  gap: 5rem;
}




.section{
  padding: 4rem 0 2rem;
}

.section_title{
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section_subtitle{
  display: block;
  font-size: var(--normal-font-size);
  margin-bottom: 4rem;
}

.section_title,
.section_subtitle{
  text-align: center;
}









.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 2rem;
}





.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover{
  background-color: var(--title-color-dark);
}

.button_icon {
  margin-left: var(--mb-0-5);
}

.button--flex{
  display: inline-flex;
  align-items: center;
}





@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 1.5rem;
  }

  .button_icon {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding: 2rem 0 4rem;
  }

  .section_subtitle {
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px) {

}

@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.75rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
