.home_container {
    row-gap: 7rem;
}

.home_content {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home_img {
    background: url(../../assets/moi_1.png);
    background-color: var(--text-color);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgba(255, 255, 255, 0.3);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    
}

.home_title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home_hand {
    width: 38px;
    height: 36px;
    margin-left: var(--mb-0-5);
}

.home_subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home_subtitle::before {
    content: '';
    position: absolute;
    left: 0;
    top: 1rem;
    width: 50px;
    height: 1px;
    background-color: var(--text-color);   
}

.home_description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(4rem);
    }
}

.home_scroll-name {
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home_scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home_scroll {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .home_content {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home_hand {
        width: 26px;
        height: 26px;
    }

    .home_subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home_subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home_description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home_img {
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 7px rgba(255, 255, 255, 0.3);
    }
  }
  
  @media screen and (max-width: 768px) {
    .home_content {
        grid-template-columns: 0.5fr 1fr;
        padding-top: 3rem;
    }

    .home_img {
        order: initial;
        justify-self: initial;
        box-shadow: inset 0 0 0 7px rgba(255, 255, 255, 0.3);
        width: 200px;
        height: 200px;
    }

    .home_data {
        grid-column: 1/3;
    }

    .home_scroll {
        display: none;
    }
  }
  
  @media screen and (max-width: 350px) {
    .home_img {
        width: 150px;
        height: 150px;
    }

    .home_hand {
        width: 22px;
        height: 22px;
    }


  }