.footer {
    background-color: var(--title-color);
    border-top: 1px solid #e9ecef;
    padding: 3rem 0 2rem 0;
    margin-top: 4rem;
}

.footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.footer_logo {
    width: 100px;
    height: 100px;
    background-color: var(--container-color);
    border-radius: 0.5rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.footer_copy {
    font-size: var(--normal-font-size);
    color: var(--container-color);
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 3rem 0 6rem 0;
    }
  }

  @media screen and (max-width: 576px) {
  }

  @media screen and (max-width: 350px) {
  }