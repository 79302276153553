.contact_container {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    column-gap: 3rem;
    justify-content: center;
}

.contact_card {
    background-color: var(--container-color);
    padding: 2rem 3rem;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact_card-title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-bold);

}

.contact_card-data {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.contact_icon {
    font-size: 2rem;
    color: var(--title-color);
}


@media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 768px) {
    .contact_container {
        grid-template-columns: max-content;
        row-gap: 3rem;
    }
  }

  @media screen and (max-width: 576px) {
  }

  @media screen and (max-width: 350px) {
    .contact_card {
        padding: 2rem 2rem;
    }
  }