.skills_container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills_content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.skills_title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-bold);
    text-align: center;
    margin-bottom: var(--mb-2);
}

.skills_box {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
}

.skills_group {
    display: grid;
    align-items: flex-start;
    row-gap: 1.5rem;
}

.skills_data {
    display: flex;
    column-gap: 0.5rem;
}

.skills .bx-badge-check {
    font-size: 1.5rem;
    color: var(--title-color);
}

.skills_name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.skills_level {
    font-size: var(--small-font-size);
}



@media screen and (max-width: 992px) {
    .skills_container {
        grid-template-columns: max-content;
        row-gap: 3rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    
  }
  
  @media screen and (max-width: 576px) {
    .skills_container {
      grid-template-columns: 1fr;
    }
  
    .skills_content {
      padding: 2rem;
    }
  }
  
  @media screen and (max-width: 350px) {
    .skills_box {
        column-gap: 1rem;
    }

    .skills_name {
        font-size: var(--small-font-size);
    }
  }