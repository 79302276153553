.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav_logo,
.nav_logo-link {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.nav_toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav_list {
    display: flex;
    column-gap: 2rem;
}

.nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--normal-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
    transition: .3s;
}

.nav_icon,
.nav_close,
.nav_toggle {
    display: none;
}

/* .active-link, */
.nav_link:hover {
    color: var(--title-color-dark);
}

.scroll-header {
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
}




@media screen and (max-width: 768px) {
    .header {
        top: initial;
        bottom: 0;
    }

    .nav {
        height: 4rem;
    }

    .nav_menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        padding: 2rem 1.5rem 4rem;
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    .show-menu {
        bottom: 0;
    }

    .nav_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    .nav_icon {
        font-size: 1.5rem;
    }

    .nav_close {
        position: absolute;
        right: 1.25rem;
        bottom: .5rem;
        font-size: 2rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav_close:hover {
        color: var(--title-color-dark);
    }

    .nav_toggle {
        font-size: 2rem;
        cursor: pointer;
    }

    .nav_icon,
    .nav_close,
    .nav_toggle {
        display: block;
    }
    
    .scroll-header {
        box-shadow: 0 -4px 2px rgba(0, 0, 0, 0.1);
    }
}



@media screen and (max-width: 350px) {
    .nav_menu {
        padding: 2rem 0.5rem 4rem;
    }

    .nav_list {
        column-gap: 0;
    }
}