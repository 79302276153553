.about_container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about_img {
    width: 350px;
    border-radius: 1.5rem;
    justify-content: center;
}
.about_info {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about_description {
    margin-bottom: var(--mb-2-5);
}

@media screen and (max-width: 992px) {
    .about_container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .about_img {
        width: 220px;
        justify-self: center;
    }

    .about_data {
        text-align: center;
    }

    .about_description {
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about_description {
        padding: 0 2.5rem;
    }
  }
  
  @media screen and (max-width: 576px) {
  
  }
  
  @media screen and (max-width: 350px) {
  }